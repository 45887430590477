import React from "react"
import "./product-card.scss"

function ProductCard(props) {
    return (
        <div class="flex rounded-lg p-2 sm:flex-row flex-col mb-8 items-center">
        <div class="w-24 h-24 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full flex-shrink-0">
          <img src={props.image}/>
        </div>
        <div class="flex-grow">
          <h2 class="text-gray-900 text-xl title-font font-medium">{ props.title }</h2>
          <p class="leading-relaxed text-sm">{ props.description }</p>
        </div>
      </div>
    );
  }

export default ProductCard
