import React from "react"

import Layout from "../components/layout"
import Meta from "../components/seo"

import ProductCard from "../components/product-card/product-card"

import ap1 from "../images/apple-product-1.png"
import ap2 from "../images/apple-product-2.png"
import ap3 from "../images/apple-product-3.png"
import ap4 from "../images/apple-product-4.png"

const Services = () => (
  <Layout>
    <Meta title="Fresh peeled and sliced apple products | Greenville Apple Products"
          description="We offer a wide range sliced apple products of various shapes and sizes"
          url="https://www.greenvilleappleproducts.com/products" 
          />
    <div className="container pad">
    <article className="prose gray py-4 mb-6">
      <h1>Our Products</h1>
      <h3>We offer a wide range of apple products of various cuts and sizes to fit your needs</h3>
    </article>
    <ProductCard title="Sliced Apples (Various Sizes)" description="Sliced into wedge forms" image={ap1}></ProductCard>
    <ProductCard title="Dumplings" description="Whole peeled and cored apples" image={ap2}></ProductCard>
    <ProductCard title="Sliced Apple Rings (Various Sizes)" description="Cut into rings and half rings " image={ap3}></ProductCard>
    <ProductCard title="Diced Apples (Various Sizes)" description="1/4 “, 1/2” or 3/4”  cubed " image={ap4}></ProductCard>
    </div>
  </Layout>
)

export default Services
